(function ($,window,document) {
    $(window).scroll(function () {
        var scrollTopValue = $(window).scrollTop();
        var headerLogowrapper = $(".header__logowrapper:not(.hamburger__logowrapper)");
        var docHeight = $(document).height() - $(window).height() - 525;


        if( scrollTopValue > 100){
            headerLogowrapper.addClass('scrolled-v');
            $("header").addClass('scrolled-v');
            $("[header-lowermenu]").slideUp();
        }
        else{
            headerLogowrapper.removeClass('scrolled-v');
            $("header").removeClass('scrolled-v');
            $("[header-lowermenu]").slideDown();

        }
        if(scrollTopValue > docHeight){
            $("[sidebar]").addClass('hidden');
        }
        else{
            $("[sidebar]").removeClass('hidden');
        }
        $(".hometext").addClass('scrolled');
    });
    $("a[search]").click(function () {
        $("[searchfield]").fadeIn();
        setTimeout(function () {
            $("[searchfield]").addClass('opened');
        },400);
    });
    $("[search-close]").click(function () {
        $("[searchfield]").fadeOut();
    });
    $('.header__lowerlink').click(function (e) {

        e.preventDefault(); // prevent default anchor behavior
        var goTo = this.getAttribute("href"); // store anchor href
        $('[loader]').fadeIn('slow');
        setTimeout(function()
            { window.location = goTo; }
            ,1000);// Delay in milliseconds
    });

    $(document).click(function(event) {


                if(!$(event.target).closest('[searchmidwrap]').length) {
                    $("[searchfield][class*='opened']").fadeOut();
                    $("[searchfield]").removeClass('opened');
                }





    });
})(jQuery,window,document);