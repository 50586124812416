
(function ($) {
    $(document).ready(function () {
       var table_module = $('[table-module]');
       var table = $('.contenttable');


        if(table_module.length){
            table_module.each(function () {
                var numberOfColumns = $('tr:first > td').length
            });
        }

    });
})
(jQuery);