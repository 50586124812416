(function($) {
    var cookie_note = $('[cookie-note]');

    //var smart_teaser = $('[smart-teaser]');
    $(document).ready(function() {
        if ($('[cookie-note]').length) {
            $('[cookie-note]').each(function() {
                var close_button = $(this).find('[cookie-close]');

                cookieNoteCheck();

                function cookieNoteCheck() {
                    if ($.cookie('cookieNoteVal') == 1) {
                        cookie_note.hide();
                    } else {
                        cookie_note.fadeIn();
                        //change the function if requirement comes

                    }
                    close_button.on('click', function() {
                        $.cookie('cookieNoteVal', 1, {
                            expires: 10,
                            path: '/'
                        });
                        setTimeout(function() {
                            cookie_note.fadeOut();
                        }, 100);
                    });
                }

            });
        }
    });
})
(jQuery);