(function ($) {
    var tabsMenuItem = $('[tabs-menu-item]');
    var tabPane = $('[tab-pane]');
    var tabMenuLinks = $('.tabs-menu__link a');

    //Add arrow to each menu item
    tabMenuLinks.each(function(){
        $(this).prepend('<span class="icon-arrow-right"></span>');
    });

    tabsMenuItem.click(function(){
        var tabsTarget = $(this).attr('data-target');
        var tabsSource = $('[tab-pane]').attr('data-target');
        var tab_id = tabsTarget;
        console.log(tab_id);

        tabsMenuItem.removeClass('active');
        tabPane.removeClass('active');

        $(this).addClass('active');
        $("#"+tab_id).addClass('active');
    });
})
(jQuery);