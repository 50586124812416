(function ($) {
    $("[tab-content][data-content='tab-0']").addClass('active');
    $(".tab-content-1").addClass('active');
    $("[class*='tab-content']").closest('.site-wrapper').addClass('pad-canc');

    $("[tab-link]").click(function (e) {
        e.preventDefault();
        var dataTarget= $(this).attr('data-target');
        var dataValue= $(this).attr('data-value');

        $("#powermail_field_contactcategory").attr('value',dataValue);
        $("[tab-link]").removeClass('active');
        $(this).addClass('active');
        $("[tab-content]").removeClass('active');
        $("[class*='tab-content']").removeClass('active');
        $("[tab-content][data-content='"+dataTarget+"']").addClass('active');
        $(".row."+dataTarget).addClass('active');
    });
    var hrefurl=$(location).attr("href");
    var redirectedTab=hrefurl.substr(hrefurl.lastIndexOf('=') + 1);
    if(redirectedTab.indexOf('tab-content') != -1){
        $("[data-target='"+redirectedTab+"']").trigger('click');
    }
    else{
        $("[tab-link]").first().trigger('click');
    }
    setTimeout(function () {

        if($('.form__wrapper').length > 0){
            $('.form__wrapper').each(function () {
                var thisAction =$(this).find('form').attr('action');
                var closestTabClases=$(this).closest('.row').attr('class');

                var closestTab = closestTabClases.split(' ')[1];
              
                $(this).find('form').attr('action',thisAction + '&tab='+closestTab);
            })
        }
    },500);


})(jQuery);
