(function ($) {
    $(document).ready(function () {
        var contactMenuButton = $('[contact-menu-button]');
        var contactMenu = $('[contact-menu]');
        var closeContactMenu = $('[close-contact-menu]');

        contactMenuButton.click(function () {
            contactMenu.toggleClass('contact-menu__active');
        });

        closeContactMenu.click(function () {
            contactMenu.removeClass('contact-menu__active');
        })
    });
})
(jQuery);