
/* This functionality is essential for responsive behaviour of teaser */
(function ($) {
    $(".row.parent").children('.col-md-8').addClass('col-md-12 col-sm-12').removeClass('col-sm-6 col-md-8');
    $(".page-subcontent .column").each(function () {
        $(this).addClass('col-sm-12 col-md-12').removeClass('col-sm-4 col-md-4');
    })
    if( $(".container.inner-page").length > 0){
        $('body,header').addClass('inner-page');
    }
    var activePage = $(location).attr("href").split('/').slice(-2)[0];
    $(".sidebar__link.highlight[href='/"+activePage+"/']").addClass('active');

    if($('video').length > 0){
        if($(window).width() > 768){
            $('video').get(0).play();
            var refreshIntervalId = setInterval(function () {
                $('video').get(0).play();
                console.log('played');
            },3000);
            setTimeout(function () {
                clearInterval(refreshIntervalId);
            },60000)
        }

    }
    if($(window).width() < 768){
        $('video').remove();
    }



    if($(".bottom-teaser-box .teaser").length < 1){
        $(".bottom-teaser-box").hide();
    }

    setTimeout(function () {
        $(".hometext").addClass('scrolled');
    },2000);

    $(".internal-link").click(function (e) {
        var anchorTag = $(this).attr('href');
        var divToScroll = anchorTag.split('/').pop();
        if($(divToScroll).length > 0){
            e.preventDefault();
            var divToScrollPoint= $(divToScroll).offset().top - 150;
            $('html,body').animate({scrollTop :divToScrollPoint},300);
        }
    });

})(jQuery);

(function ($,window) {

})(jQuery,window);


(function (jQuery,document) {
    $(document).ready(function () {
        $(".tab__nav a").click(function () {
            if($("#powermail_field_datumunduhrzeitmez").length){
                console.log("huaa");
                $("#powermail_field_datumunduhrzeitmez").datetimepicker();
            }
        });
    });
})(jQuery,document);
