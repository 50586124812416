
(function ($) {
    var linkActive = $('.lang-drop__item--active');

    $(document).ready(function () {
    //Add arrow to each menu item
        linkActive.each(function(){
            $(this).append('<span class="icon-chevron-down"></span>');
        });
    });
})
(jQuery);