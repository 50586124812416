
(function ($) {
    var linkInternal = $('.link__internal a');
    var linkExternal = $('.link__external a');
    var linkDownload = $('.link__download a');
    var linkAnchor = $('.link__anchor a');
    var linkBreadcrump = $('.section-bread span:first a');
    $(document).ready(function () {
    //Add arrow to each menu item
        linkInternal.each(function(){
            $(this).append('<span class="icon-arrow-right"></span>');
        });
        linkExternal.each(function(){
            $(this).append('<span class="icon-log-out"></span>');
        });
        linkDownload.each(function(){
            $(this).append('<span class="icon-download"></span>');
        });
        linkAnchor.each(function(){
            $(this).append('<span class="icon-chevrons-down"></span>');
        });
        linkBreadcrump.each(function(){
            $(this).prepend('<span class="icon-corner-left-up"></span>');
            $(this).addClass('breadcrump-home');
        });
    });
})
(jQuery);