(function ($) {

    var yearSelected,typeSelected,yearList=[],typeList=[],yearOptions,typeOptions;
    var newsTypeSelecter = $('#newsTypeSelect');
    var newsYearSelecter = $('#newsYearSelect');


    paginationGroup();
    //Generate Year
    $("[data-newsyear]").each(function () {
        var yearValue =$(this).attr('data-newsyear');
        if (yearList.indexOf(yearValue)==-1) yearList.push(yearValue);
    });

    // Generate News Type
    $('[data-newstype]').each(function () {
        var typeValue =$(this).attr('data-newstype');
        if (typeList.indexOf(typeValue)==-1) typeList.push(typeValue);
    });

    //Generate YearDropdown
    for (var i = 0; i < yearList.length; i++) {
        yearOptions += '<option value="'+yearList[i]+'">'+ yearList[i] +'</option>';
    }
    newsYearSelecter.append(yearOptions);

    //Generate typeDropdown
    for (var i = 0; i < typeList.length; i++) {
        typeOptions += '<option value="'+typeList[i]+'">'+ typeList[i] +'</option>';
    }
    newsTypeSelecter.append(typeOptions);


    $('[data-newsyear]').addClass('shown');
    newsYearSelecter.on('change',function () {
            newsTypeSelecter.selectric('destroy')
            newsTypeSelecter.prop("selectedIndex", 0);
            newsTypeSelecter.selectric();
            var selectedYear =this.value;
            $('[data-newsyear]').removeAttr("yearSelected");
            if(selectedYear !='all'){
                console.log(selectedYear);
                $('[data-newsyear]').removeClass('shown');
                $('[data-newsyear="'+selectedYear+'"]').addClass('shown');
                $('[data-newsyear="'+selectedYear+'"]').attr('yearSelected',"");
                yearSelected = true;
                paginationGroup();
                return yearSelected;

            }
            else{
                $('[data-newsyear]').addClass('shown');
                $('[data-newsyear]').removeAttr("yearSelected");
                yearSelected = false;
                paginationGroup();
                return yearSelected;

            }

        });
            newsTypeSelecter.on('change',function () {
            var selectedType =this.value;
            if(selectedType !='all'){
                $('[data-newstype]').removeClass('shown');
                if(yearSelected){
                    $('[yearSelected][data-newstype="'+selectedType+'"]').addClass('shown');
                }
                else{
                    $('[data-newstype="'+selectedType+'"]').addClass('shown');
                }


            }
            else{
                if(yearSelected){
                    $('[yearSelected]').addClass('shown');
                }
                else{
                    $('[data-newstype]').addClass('shown');
                }

            }
                paginationGroup();
        });
    var activePage;
        function paginationGroup() {
            setTimeout(function () {
               activePage =1;
                var paginateNewsBlock = $(".newslist__item.shown");
                $("[page] > div").unwrap();
                for(var i = 0; i < paginateNewsBlock.length; i+=4) {
                    paginateNewsBlock.slice(i, i+4).wrapAll("<article class='page' page></article>");
                }
                $("[page]").first().addClass('visible');
                pageNumber(activePage);

            },200);

            function pageNumber(activePage) {
                console.log(activePage)
                $(".eqs-widget-paginator").html('');
                var paginationGroupCount = $("[page]").length;
                if($("[page]").length > 0){
                    $(".eqs-widget-paginator").append('<span pagination-body><span class="pages-iconfont left-arrow"><</span><span class="pageNum">'+activePage+'<span class="pages-iconfont pages-trenner">|</span><span class="pageCount">'+paginationGroupCount+'</span><span class="pages-iconfont right-arrow">></span></span>')
                    return false;
                }
                return false;

            }
            $(document).on('click','.pages-iconfont.right-arrow',function (e) {

                if($("[page].visible").nextAll("[page]").length > 0){
                    $("[page].visible").nextAll("[page]").first().addClass('visible');
                    $("[page].visible").prevAll("[page]").first().removeClass('visible');
                    activePage++ ;
                    pageNumber(activePage);
                    e.stopImmediatePropagation();
                }




            });
            $(document).on('click','.pages-iconfont.left-arrow',function (e) {

                if($("[page].visible").prevAll("[page]").length > 0){
                    $("[page].visible").prevAll("[page]").first().addClass('visible');
                    $("[page].visible").nextAll("[page]").first().removeClass('visible');

                    activePage-- ;
                    pageNumber(activePage);
                    e.stopImmediatePropagation();
                }


            });

        }
})(jQuery);