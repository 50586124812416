(function ($) {
    var primaryMenuItem = $('[primary-menu-item]');
    var primaryPane = $('[primary-menu-pane]');
    var primaryMenuLinks = $('.primary-navigation__link a');

    //Add arrow to each menu item
    primaryMenuLinks.each(function(){
        //$(this).prepend('<span class="icon-arrow-right"></span>');
    });

    primaryMenuItem.click(function(){
        var primaryTarget = $(this).attr('data-target');
        var tabsSource = $('[primary-menu-pane]').attr('data-target');
        var tab_id = primaryTarget;
        console.log(tab_id);

        primaryMenuItem.removeClass('active');
        primaryPane.removeClass('active');

        $(this).addClass('active');
        $("#"+tab_id).addClass('active');
    });
})
(jQuery);