(function ($) {
    $(document).ready(function () {
        var swiperSpeed = $('.swiper-wrapper').data("speed");
        var swiperSetManualTransition = $('.swiper-wrapper').data("transition");
        var gallerySwiper = $('[gallery-swiper]');

        var swiper = new Swiper('[slider-swiper]', {
            slidesPerView: 1,
            spaceBetween: 30,
            loop: true,
            effect: 'fade',
            disableOnInteraction:false,
            autoplay: {
                delay: swiperSpeed
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                type: 'bullets'
            },
            navigation: {
                nextEl: '.slider-swiper-swiper-button-next',
                prevEl: '.slider-swiper-swiper-button-prev'
            },
        });
        if($('[slider-swiper]').length){
            if (swiperSetManualTransition == 1) {
                swiper.autoplay.start();
            }
            else {
                swiper.autoplay.stop();
            }
        }


        var counter = 0;

        if(gallerySwiper.length){
            let swiperInstances = [];
            gallerySwiper.each(function(index, element){ //some-slider-wrap-in
                const $this = $(this);
                $this.addClass("instance-" + index); //instance need to be unique (ex: some-slider)
                $this.parent().find(".swiper-pagination").addClass("pagination-" + index);
                $this.parent().find(".swiper-button-prev").addClass("prev-" + index); //prev must be unique (ex: some-slider-prev)
                $this.parent().find(".swiper-button-next").addClass("next-" + index); //next must be unique (ex: some-slider-next)
                swiperInstances[index] = new Swiper(".instance-" + index, { //instance need to be unique (ex: some-slider)
                    // your settings ...
                    slidesPerView: 5,
                    spaceBetween: 30,
                    navigation: {
                        prevEl: ".prev-" + index,  //prev must be unique (ex: some-slider-prev)
                        nextEl: ".next-" + index, //next must be unique (ex: some-slider-next)
                    },
                    pagination: {
                        el: '.pagination-' + index,
                        type: 'bullets',
                        clickable: true
                    },
                    // Responsive breakpoints
                    breakpoints: {
                        // when window width is <= 320px
                        320: {
                            slidesPerView: 1,
                                spaceBetween: 10
                        },
                        // when window width is <= 480px
                        480: {
                            slidesPerView: 2,
                                spaceBetween: 20
                        },
                        // when window width is <= 640px
                        640: {
                            slidesPerView: 3,
                                spaceBetween: 30
                        }
                    }
                });

            });

            // Now you can call the update on a specific instance in the "swiperInstances" object
            setTimeout(function () {
                for (var i = 0; i>=swiperInstances.length; i++){
                    swiperInstances[i].update();
                }
            }, 50);

            gallerySwiper.each(function () {
                var items = $(this).find('[gallery-item]').length;
                if(items <= 5){
                    $(this).addClass('remove-slider-function');
                    $(this).parent('.swiper-holder').find('.swiper-pagination-container').addClass('remove-slider-function');
                }

            });



        }




    });
})
(jQuery);

