
// (function ($) {
//
//         $("a.fancybox").fancybox({
//             helpers: {
//                 title : {
//                     type : 'inside'
//                 },
//                 overlay : {
//                     speedOut : 0
//                 }
//             }
//         });
//
// })
// (jQuery);