(function ($,document) {
    if($('[carrier-list]').length > 0 ){
        $.getScript('https://static.b-ite.com/js/jobs-api.js', function(){



            if($('[carrier-list]').attr('data-language') == "german"){
                var lang = "de";
                var no_vacancies = "Derzeit liegen keine Stellenangebote vor.";
            }
            else{
                var lang = "en";
                var no_vacancies = "Currently there are no vacancies.";
            }
            var api_key = "d3ca65ce075039ee3c1d84caf1a2e14b690d7e58";


            var options =
            {
                apikey: api_key,
                classprefix: "jobadsloader_",
                columns: ["title"],
                datetime:
                {
                    filter:
                    {
                        enable: false,
                        start: "06.06.2014",
                        end: "06.12.2030"
                    },
                    format: "d.m.Y"
                },
                language:
                {
                    filter:
                    {
                        enable: false,
                        value: lang
                    },
                    ui: lang
                },
                pager:
                {
                    enable: false,
                    perPage: 5
                },
                sort:
                {
                    by: "title",
                    order: "asc"
                },
                url:
                {
                    enable: true,
                    column: "title"
                },
                jquery_force_load: true,
                no_results_html: no_vacancies
            };

            var loader = new jobAdsLoader();
            loader.show('#jobads', options);

        });


        $(document).on('focus','.jobadsloader_inputs select',function () {
            $(this).parent().addClass('opened');
        });
        $(document).on('focusout change','.jobadsloader_inputs select',function () {
            $(this).parent().removeClass('opened');
        });


    }
})(jQuery,document);