(function ($) {
    $(document).ready(function () {
        var smart_teaser = $('[smart-teaser]');

        if($('[smart-teaser]').length){
            $('[smart-teaser]').each(function () {
                var close_button = $(this).find('[close-notification-smart-teaser]');
                cookieCheck();

                function cookieCheck(){
                    if($.cookie('cookieVal')==1){
                        smart_teaser.hide();
                    }else{
                        smart_teaser.fadeIn();
                    }
                    close_button.on('click', function(){
                        $.cookie('cookieVal', 1, { expires: 10, path : '/'});
                        setTimeout(function(){smart_teaser.fadeOut();},500);
                    });
                }

            });
        }
    });
})
(jQuery);