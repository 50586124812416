(function ($) {
    $(document).ready(function () {
        if($('[tab-container]').length){
            $('[tab-container]').each(function () {
                var tabContainerItem = $(this).find('[tab-container-item]');
                var tabContainerPane = $(this).find('[tab-container-pane]');
                tabContainerItem.click(function(){
                    var tabContainerTarget = $(this).attr('data-target');
                    tabContainerItem.removeClass('active');
                    tabContainerPane.removeClass('active');
                    tabContainerPane.each(function () {
                        if($(this).attr("data-source")==tabContainerTarget){
                            $(this).addClass('active');
                        }
                    });
                    $(this).addClass('active');
                });
            });
        }

        function setHeight(){
            if($('[contact-menu]').length){
                $('[contact-menu] [tab-container]').each(function () {
                    var paneHeight = $(this).find('.tabbed-content__content .active').outerHeight();
                    $(this).find('.tabbed-content__content').height(paneHeight);
                    $(this).find('[tab-container-item]').click(function () {
                        var paneHeight = $(this).parent().parent().find('.tabbed-content__content .active').outerHeight();
                        $(this).parent().parent().find('.tabbed-content__content').height(paneHeight);
                    })
                });
            }
        }
        setHeight();
    });
})
(jQuery);