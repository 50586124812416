
(function ($) {
    $(document).ready(function () {
        if($('.reference-page').length){
            var image = $('.reference-page').find('.banner-image-category').css('background-image');

            if(image.length){
                $('.reference-page__background-image').css('background-image', image);
            }
        }
    });
})
(jQuery);