(function ($) {
    $(document).ready(function () {
        function accordion() {
            $('[accordion-link]').click(function () {
                var isSingle = $(this).parentsUntil('[accordion-single]').parent('[accordion-single]').length;
                if(isSingle==1){
                    if($(this).find('[accordion-head-icon]').hasClass('accordion__head-icon_expanded')){
                        $(this).find('[accordion-head-icon]').removeClass('accordion__head-icon_expanded');
                        $(this).next('[accordion-content]').slideUp();
                    }else {
                        $(this).parent('div').siblings('div').find('[accordion-head-icon]').removeClass('accordion__head-icon_expanded');
                        $(this).parent('div').siblings('div').find('[accordion-content]').slideUp();
                        $(this).find('[accordion-head-icon]').addClass('accordion__head-icon_expanded');
                        $(this).next('[accordion-content]').slideDown();
                    }
                }else{
                    $(this).siblings('[accordion-content]').slideToggle();
                    if($(this).find('[accordion-head-icon]').hasClass('accordion__head-icon_expanded')){
                        $(this).find('[accordion-head-icon]').removeClass('accordion__head-icon_expanded');
                    }else {
                        $(this).find('[accordion-head-icon]').addClass('accordion__head-icon_expanded');
                    }
                }

            });
        }
        accordion();



    });

    $("[accordion-title]").click(function () {
        var singleOnly,allExpand = false;
        var accordionParent = $(this).closest('[accordion]');
        if(accordionParent.attr('accordion-single-only')!== undefined){
             singleOnly = true
        }
        if(accordionParent.attr('accordion-all-expanded')!== undefined){
            allExpand = true
        }
        console.log(singleOnly)
        if(singleOnly){
            $("[accordion-title]").not($(this)).removeClass('active');
            $("[accordion-content]").not($(this).next('[accordion-content]')).slideUp();
        }
        $(this).next('[accordion-content]').slideToggle();
        $(this).toggleClass('active');

    });
    var isExpandAll = $('[accordion-all-expanded]').length;
    function accordionExpandAll() {
        if(isExpandAll!=0){
            console.log(1);
            $("[accordion-title]").addClass('active');
            $('[accordion-all-expanded]').each(function () {

                $(this).find('[accordion-content]').slideDown();
            })
        }
    }
    accordionExpandAll();
})
(jQuery);


