(function ($) {
        // Hamburger Layout changes
        var tobeWrapped = $(".main-nav-level-1 > li");
        for(var i = 0; i < tobeWrapped.length; i+=4) {
            tobeWrapped.slice(i, i+4).wrapAll("<li><ul class='main-nav-level-1'></ul></li>");
        }
        if($(".main-nav-level-1").parent('li').parent(".main-nav-level-1")){
            $(".hamburger__menu").find('.main-nav-level-1').first().unwrap().unwrap();
        }
        /////

        var tobeGrouped =  $(".main-nav-level-1 .main-nav-level-1 > li");
        for(var i = 0; i < tobeGrouped.length; i+=2) {
            tobeGrouped.slice(i, i+2).wrapAll("<div class='menu-group' menu-group></div>");
        }
        // It ENDS

        $("[hamburger-open]").click(function (e) {
            e.preventDefault();
            $("[hamburger-menu]").fadeIn();
            $("li[hasSub]:not(.enabled)").each(function () {
                $(this).children('a').append('<i class="navigation-button-arrow">></i>');
                $(this).addClass('enabled');
            });
            $("body").addClass('overflow');
        });
        $("[hamburger-close]").click(function (e) {
            e.preventDefault();
            $("[hamburger-menu]").fadeOut();
            $("body").removeClass('overflow');
        });
        // $("[hamburger-menu] a").click(function (e) {
        //     e.preventDefault();
        //     $("[hamburger-menu] > ul > li > ul > li > ul").not($(this).next('ul')).slideUp();
        //     $(this).next('ul').slideToggle();
        //     $("[hamburger-menu] > ul > li > ul > li > a").not($(this)).removeClass('active');
        //     $(this).toggleClass('active');
        // });
    $("[hamburger-menu] a").click(function (e) {

        var subLevelExist = $(this).parent().attr('hassub')
        if (typeof subLevelExist !== typeof undefined && subLevelExist !== false) {
            e.preventDefault();
            $(this).next('ul').slideToggle();
            $(this).parent('[hassub]').siblings('[hassub]').children('ul').slideUp();
            $(this).parent('[hassub]').siblings('[hassub]').children('a').removeClass('active');
            $(this).parent('[hassub]').parent('[menu-group]').siblings('[menu-group]').children('[hassub]').children('ul').slideUp();
            $(this).parent('[hassub]').parent('[menu-group]').siblings('[menu-group]').children('[hassub]').children('a').removeClass('active');
            $(this).toggleClass('active');
        }



    });
})(jQuery);