(function ($,document) {
    var paginationCount = $(".eqs-widget-paginator > li").length;
    var pagination = function(paginationCount) {
        $("[pagination-body]").remove();
        var activePage= $(".eqs-widget-paginator > li.active").index() + 1;
        $(".eqs-widget-paginator").append('<span pagination-body><span class="pages-iconfont left-arrow"><</span><span class="pageNum">'+activePage+'<span class="pages-iconfont pages-trenner">|</span><span class="pageCount">'+paginationCount+'</span><span class="pages-iconfont right-arrow">></span></span>')
    };
    $(document).on('click','.pages-iconfont.right-arrow',function () {
        if($(".eqs-widget-paginator > li.active").next().length > 0){
            window.location  =  $(".eqs-widget-paginator > li.active").next().find('a').attr('href');
        }

    });
    $(document).on('click','.pages-iconfont.left-arrow',function () {
        if($(".eqs-widget-paginator > li.active").prev().length > 0){
            window.location  =  $(".eqs-widget-paginator > li.active").prev().find('a').attr('href');
        }

    });
    if(paginationCount >0){
        pagination(paginationCount);
    }


})(jQuery,document);

